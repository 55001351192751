export const DEFAULTS = {
	enableItems: {
		singleEnableItems: {
			complementaryArea: {
				'core/edit-site': 'edit-site/block-inspector',
				'core/edit-post': 'edit-post/document',
			},
		},
	},
};
