/**
 * WordPress dependencies
 */
import { SVG, Path } from '@wordpress/primitives';

const upload = (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<Path d="M18.5 15v3.5H13V6.7l4.5 4.1 1-1.1-5.7-5.3-.6.6-.5-.5-5.2 5.2 1 1.1 4-4v11.7h-6V15H4v5h16v-5z" />
	</SVG>
);

export default upload;
