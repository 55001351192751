/**
 * WordPress dependencies
 */
import { SVG, Path } from '@wordpress/primitives';

const arrowLeft = (
	<SVG xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<Path d="M9.737 18.011L3.98 12.255l5.734-6.28 1.107 1.012-4.103 4.494h13.3v1.5H6.828l3.97 3.97-1.06 1.06z" />
	</SVG>
);

export default arrowLeft;
